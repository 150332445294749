/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { isLoggedIn, logout } from '../utils/auth'
import { Box, Text } from './ui'

const MembersLogout = () => {
  const loggedIn = isLoggedIn()

  React.useEffect(() => {
    if (loggedIn) {
      console.log('Logging out...')
      logout().then(() => {
        window.location.reload()
      })
    }
  }, [loggedIn])

  return (
    <Box>
      {isLoggedIn() ? (
        <Text>Logging out...</Text>
      ) : (
        <Text>You are now logged out.</Text>
      )}
    </Box>
  )
}

export default MembersLogout
