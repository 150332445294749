/** @jsx jsx */
import { useState } from 'react'
import { jsx } from 'theme-ui'
import { Field, Button, Spinner, Text } from './ui'

const LoginForm = ({ onSubmit, error, loading }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleChange = update => e => {
    update(e.target.value)
  }

  const doLogin = () => {
    onSubmit(email, password)
  }

  return (
    <div sx={styles.container}>
      <Field
        sx={styles.field}
        label="Email"
        name="email"
        value={email}
        onChange={handleChange(setEmail)}
      />
      <Field
        sx={styles.field}
        label="Password"
        name="password"
        value={password}
        type="password"
        onChange={handleChange(setPassword)}
      />
      {error ? <Text variant="error">{error}</Text> : null}
      {loading ? <Spinner /> : <Button onClick={doLogin}>Login</Button>}
    </div>
  )
}

const styles = {
  container: {
    width: ['90%', '50%'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '5px',
    textAlign: 'center',
    py: [2, 3, 3],
    '& > *': {
      width: '75%',
    },
  },
  field: {
    marginBottom: [2, 3, 3],
  },
}
export default LoginForm
