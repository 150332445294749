/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { Box, Heading, Link, Field, Button, Spinner, Text } from './ui'

const MembersConfirm = () => {
  const [error, setError] = React.useState(null)
  const [email, setEmail] = React.useState('')
  const [verificationCode, setVerificationCode] = React.useState('')
  const loading = false

  const handleChange = update => e => {
    update(e.target.value)
  }

  const confirm = async () => {
    console.log('signup', email, verificationCode)
    try {
      await Auth.confirmSignUp(email, verificationCode)
      navigate('/members/login')
    } catch (err) {
      setError(err.message)
      console.error('error:', err)
    }
  }

  return (
    <Box sx={styles.container}>
      <Heading>Confirm Account:</Heading>
      <div sx={styles.form}>
        <Text>
          When you first create an account, you need to verify your email
          address. Check your email for a message containg a verification code,
          and enter it below:
        </Text>
        <Field
          sx={styles.field}
          label="Email"
          name="email"
          value={email}
          onChange={handleChange(setEmail)}
        />
        <Field
          sx={styles.field}
          label="Verification Code"
          name="verificationCode"
          value={verificationCode}
          onChange={handleChange(setVerificationCode)}
        />
        {error ? <Text variant="error">{error}</Text> : null}
        {loading ? <Spinner /> : <Button onClick={confirm}>Submit</Button>}
      </div>

      <Box sx={{ textAlign: 'center' }}>
        <Link to="/members/login">Login</Link> or{' '}
        <Link to="/members/confirm">Create An Account</Link>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    variant: 'layout.section',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: ['90%', '50%'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '5px',
    textAlign: 'center',
    py: [2, 3, 3],
    '& > *': {
      width: '75%',
    },
  },
  field: {
    marginBottom: [2, 3, 3],
  },
}

export default MembersConfirm
