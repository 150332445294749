import React from 'react'
import { isLoggedIn } from '../utils/auth'

const PrivateRoute = ({ component: Component, navigate, ...props }) => {
  const loggedIn = isLoggedIn()

  React.useEffect(() => {
    if (!loggedIn) {
      navigate('/members/login', { replace: true })
    }
  }, [loggedIn, navigate])

  if (!loggedIn) {
    return null
  }

  return <Component {...props} />
}

export default PrivateRoute
