/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Auth } from 'aws-amplify'
import { navigate } from '@reach/router'
import { Box, Heading, Link } from './ui'
import LoginForm from './LoginForm'
import { setUser } from '../utils/auth'

const MembersLogin = () => {
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(null)

  const login = async (username, password) => {
    console.log('login', username, password)
    try {
      setLoading(true)
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      console.log('user', user)
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
      navigate('/members')
    } catch (err) {
      setError(err.message)
      setLoading(false)
      console.error('error:', err)
    }
  }

  return (
    <Box sx={styles.container}>
      <Heading>Login:</Heading>
      <LoginForm onSubmit={login} error={error} loading={loading} />
      <Box sx={{ textAlign: 'center' }}>
        New here?
        <br />
        <Link to="/members/signup">Create an Account</Link> or{' '}
        <Link to="/members/confirm">Confirm Your Address</Link>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    variant: 'layout.section',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}

export default MembersLogin
