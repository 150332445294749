/** @jsx jsx */
// import React from 'react'
import { jsx } from 'theme-ui'
import { Box, Heading, Text } from './ui'

const MembersDefault = () => {
  return (
    <Box variant="layout.section">
      <Heading>Members Page</Heading>
      <Text>There's not much here yet. Stay tuned :).</Text>
    </Box>
  )
}

export default MembersDefault
