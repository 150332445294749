/** @jsx jsx */
// import React from "react"
import { jsx } from 'theme-ui'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MembersDefault from '../components/MembersDefault'
import MembersLogin from '../components/MembersLogin'
import MembersSignup from '../components/MembersSignup'
import MembersConfirm from '../components/MembersConfirm'
import MembersLogout from '../components/MembersLogout'
import PrivateRoute from '../components/PrivateRoute'

const SecretStuff = () => <div>Secret Stuff!</div>

const MembersPage = () => {
  return (
    <Layout>
      <SEO title="Members" />
      <Router basepath="/members">
        <MembersLogin path="/login" />
        <MembersSignup path="/signup" />
        <MembersConfirm path="/confirm" />
        <MembersLogout path="/logout" />
        <PrivateRoute path="/secret" component={SecretStuff} />
        <MembersDefault path="/" />
      </Router>
    </Layout>
  )
}

export default MembersPage
